var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[(_vm.showAlert)?_c('SuccessAlert',{staticClass:"alert",attrs:{"data-cy":"alert","type":_vm.alertType,"message":_vm.alertMessage}}):_vm._e()],1),_c('v-data-table',{staticClass:"px-6 mt-5",attrs:{"dense":"","search":_vm.search,"loading-text":"loading...","no-data-text":"no structure available","headers":_vm.headers,"items":_vm.getTableData,"items-per-page":10,"loading":_vm.loading,"single-expand":_vm.singleExpand,"item-key":"_uuid","show-expand":"","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.createdDate).toLocaleString()))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"data-cy":"copy","color":"primary","icon":"","x-small":""},on:{"click":function($event){return _vm.copyToClipboard(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Copy Structure to Clipboard")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"data-cy":"load_to_ui","color":"primary","icon":"","x-small":""},nativeOn:{"click":function($event){return _vm.loadToUI(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-weather-cloudy-arrow-right")])],1)]}}],null,true)},[_c('span',[_vm._v("Load Structure for re-configuration")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"data-cy":"show_info","color":"primary","icon":"","x-small":""},on:{"click":function($event){return _vm.info(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-clipboard-text-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Show Structure Configuration & Info")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"data-cy":"delete_structure","color":"primary","icon":"","x-small":""},on:{"click":function($event){return _vm.askConfirmation(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v("Description: "+_vm._s(item.description))])]}}],null,true)}),_c('ConfigurationInfo',{ref:"info",attrs:{"structure":_vm.structure,"hasTabs":_vm.hasTabs}}),_c('ConfirmDialog',{attrs:{"visible":_vm.showConfirmDialog},on:{"close":function($event){_vm.showConfirmDialog = false},"remove":_vm.deleteDataItem}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }